import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import logoStyle from "../../assets/jss/material-components/logoStyle.jsx";
import { Link } from "gatsby";
import Logo from "../../assets/img/logo.png";



function Logoimage() {
    return (
      <div>
        <Link to="/"><img src={Logo} alt="logo" className="logosize" /></Link>
      </div>
    )
  }

  export default withStyles(logoStyle)(Logoimage);